import React, { useEffect, useState } from "react"
import { Formik } from "formik"
import { navigate } from "gatsby"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import {
  pushToDataLayerLoadedStep,
  pushToDataLayerSendForm,
  pushToDataLayerStepSuccess,
  pushToDataLayerInvalidField,
} from "@ecom/ui/utils/pushToDataLayerUtils"

import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"

import * as Yup from "yup"

import Button from "@material-ui/core/Button"

import { PhoneField } from "./fields/PhoneField"
import { FioField } from "./fields/FioField"
import { BirthField } from "./fields/BirthField"
import { City, CityField } from "./fields/CityField"
import { AcceptmentField } from "./fields/AcceptmentField"

import { setIDBValue } from "../../../../utils/idbUtils"

import { sendRequest } from "../../../../helpers/sendRequest"
import { formatBirthDate } from "./helpers"

import * as styles from "./personalForm.module.scss"
import { events } from "../../../../helpers/events"
import ProgressBarGreen from "../../../ProgressBarGreen"
import { ValidationPoints } from "../../../ProgressBar/types"
import { FormValues } from "../types"
import {
  isMaxOfOneHyphen,
  isNumberOrSpecChars,
  isCyrillic,
  isItMoreThanTwoWords,
  isNumberOrSpecCharsWithoutSpace,
} from "../../../../helpers/formHelpers"

// TODO: Использовать regexp только с +7
const phoneRegExp =
  /^((\+?7|8)[ -] ?)?((\([3|4|5|6|9][0-9][0-9]\)))?([ -])(\d{3}[- ]?\d{2}[- ]?\d{2})$/

const birthRegExp = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/

const l_cities: City[] = [
  { value: "г. Красноярск", label: "Красноярск", region: "Красноярский край" },
  { value: "г. Пермь", label: "Пермь", region: "Пермский край" },
  { value: "г. Новокузнецк", label: "Новокузнецк", region: "Кемеровская область" },
  { value: "г. Уфа", label: "Уфа", region: "Республика Башкортостан" },
]

interface Response {
  requestId: string
  status: "pending" | "repeated" | "approve" | "reject" | "error"
  partnerExternalId: number
}

type Props = {
  isGreenApprove: boolean
  dataLayerName?: string
  hasBirth?: boolean
  hasCity?: boolean
  cities?: City[]
  default_city?: City
  onSubmit?: (value: Record<string, any>) => void
  formBtnText?: string
  progressBar: boolean
}

export const PersonalForm = ({
  dataLayerName = "shortPersonalForm",
  hasBirth = false,
  hasCity = false,
  cities = l_cities,
  default_city,
  onSubmit,
  formBtnText = "Получить карту",
  isGreenApprove,
  progressBar,
}: Props) => {
  const [focusPhone, setFocusPhone] = useState(false)

  const defaultSubmit = (valuesToRequest: Record<string, any>) =>
    sendRequest(`/v2/app/public/start`, { ...valuesToRequest, phoneValidate: true })
      .then(({ status, requestId }: Response) => {
        if (["pending", "reject", "repeated", "approve"].includes(status)) {
          setIDBValue("requestId", requestId)
          return Promise.resolve(status)
        }

        return Promise.reject(new Error(status))
      })
      .then((status) => {
        switch (status) {
          case "reject":
            navigate("/error-reject")
            pushToDataLayer(events.applicationSend.reject)
            return
          case "repeated":
            navigate("/error-repeat")
            pushToDataLayer(events.applicationSend.repeated)
            return
          case "error":
            throw Error(status)
          case "approve":
            pushToDataLayer(events.applicationSend.approve)
            break
          default:
            break
        }

        if ((window as any)?.ym) {
          ;(window as any).ym(process.env.GATSBY_YANDEX_ID, "reachGoal", "ApplicationSend")
        }
        navigate("/verify/")
      })
      .catch((e: Error) => {
        pushToDataLayer(events.applicationSend.error)
        pushToDataLayer(events.GAFormEvent.error)
        console.error(e, "error in sendRequest")
        navigate("/error-technical/")
      })
      .finally(() => {
        pushToDataLayerStepSuccess(dataLayerName)
      })

  const FormSchema = Yup.object().shape({
    phone: Yup.string()
      .test(
        "length",
        "Введите номер телефона полностью",
        (val) => val?.replace(/\D/g, "")?.length === 11
      )
      .matches(phoneRegExp, "Код города/оператора должен начинаться с цифры 3, 4, 5, 6, 9")
      .required("Пожалуйста, укажите номер"),
    fio: Yup.object().shape({
      data: Yup.object().shape({
        surname: Yup.mixed()
          .required("Введите фамилию и имя")
          .test(
            "surname isMaxOfOneHyphen",
            "Неверно введена фамилия",
            (value) => !isMaxOfOneHyphen(value)
          )
          .test(
            "surname isNumberOrSpecChars",
            "Фамилия не должна содержать символы или числа",
            (value) => !isNumberOrSpecChars(value)
          )
          .test("surname isCyrillic", "Фамилия не должна содержать латиницу", (value) =>
            isCyrillic(value)
          ),
        name: Yup.mixed()
          .required("Введите фамилию и имя")
          .test("name isMaxOfOneHyphen", "Неверно введено имя", (value) => !isMaxOfOneHyphen(value))
          .test(
            "name",
            "Имя не должно содержать символы или числа",
            (value) => !isNumberOrSpecChars(value)
          )
          .test("name isCyrillic", "Имя не должно содержать латиницу", (value) =>
            isCyrillic(value)
          ),
        patronymic: Yup.mixed()
          .test(
            "patronymuic isItMoreThanTwoWords",
            "Отчество не должно состоять более чем из двух слов",
            (value) => !isItMoreThanTwoWords(value)
          )
          .test(
            "patronymic",
            "Отчество не должно содержать символы или числа",
            (value) => !isNumberOrSpecCharsWithoutSpace(value)
          )
          .test("patronymic isCyrillic", "Отчество не должно содержать латиницу", (value) =>
            isCyrillic(value)
          ),
      }),
    }),
    ...(hasBirth && {
      birthDate: Yup.string()
        .matches(birthRegExp, "Пожалуйста, укажите корректную дату")
        .required("Пожалуйста, укажите дату рождения"),
    }),

    ...(hasCity && {
      localityAddress: Yup.string()
        .required("Пожалуйста, укажите город")
        .test("localityAddress isRightCity", "Данный город не соответствует выбору", (city) =>
          city ? cities.map(({ value }) => value).includes(city) : false
        ),
    }),

    agree: Yup.boolean().required().oneOf([true], "Необходимо согласие с условиями"),
  })

  const handleClick = (errors: object) => {
    Object.entries(errors).forEach((fieldKey) => {
      pushToDataLayerInvalidField(fieldKey[0], fieldKey[1], true)
    })
    pushToDataLayerSendForm()
  }

  useEffect(() => {
    if (dataLayerName) {
      pushToDataLayerLoadedStep(dataLayerName)
    }
  }, [dataLayerName])

  return (
    <Formik<FormValues>
      initialValues={{
        phone: "",
        fio: { data: { surname: null, name: null, patronymic: null } },
        agree: true,
        ...(hasBirth && { birthDate: "" }),
        ...(hasCity && { localityAddress: "", region: "" }),
      }}
      validationSchema={FormSchema}
      onSubmit={(values, { setSubmitting }) => {
        const valuesToRequest = {
          agree: values.agree,
          surname: values.fio.data.surname,
          name: values.fio.data.name,
          patronymic: values.fio.data.patronymic,
          phone: values.phone.replace(/[- )(+]/g, ""),
          productName: "КК Халва",
          additionalData: getAdditionalData(),
          ...(hasBirth && {
            birthDate: formatBirthDate(values.birthDate),
          }),
          ...((hasCity || default_city) && {
            localityAddress: { value: values.localityAddress || default_city?.value },
            region: values.region || default_city?.region,
          }),
        }
        setSubmitting(true)

        setIDBValue("phone", valuesToRequest.phone)

        if (!onSubmit) {
          defaultSubmit(valuesToRequest)
        } else {
          onSubmit(valuesToRequest)
        }
      }}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => {
        const FIO = values.fio.data

        const resultPercentageGreenBar =
          (!errors.fio
            ? (FIO.patronymic && FIO.patronymic?.length > 0
                ? ValidationPoints.FIRST_LETTER
                : ValidationPoints.INCORRECT_VALIDATION) +
              (FIO.name && FIO.name?.length > 0
                ? ValidationPoints.FIRST_LETTER
                : ValidationPoints.INCORRECT_VALIDATION) +
              (FIO.surname && FIO.surname?.length > 0
                ? ValidationPoints.FIRST_LETTER
                : ValidationPoints.INCORRECT_VALIDATION)
            : 0) +
          (!errors.phone
            ? ValidationPoints.CORRECT_VALIDATION
            : ValidationPoints.INCORRECT_VALIDATION)
        return (
          <form onSubmit={handleSubmit} className={styles.form}>
            {progressBar && (
              <ProgressBarGreen
                steps={1}
                widthAnimationBar={50}
                widthGreenBar={resultPercentageGreenBar}
              />
            )}
            {/* TODO: вынести fields отдельно. Для каждого fields в идеале сделать обертку. PersonalForm должен быть оберткой, куда спускаются fields в качестве children */}
            <FioField
              fioValue={values.fio}
              isGreenApprove={isGreenApprove}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              errors={errors.fio}
              touched={touched.fio}
              name="fio"
              id="fio"
              type="fio"
              setFocusPhone={setFocusPhone}
            />
            <PhoneField
              focus={focusPhone}
              isFioFieldValidate={Boolean(!errors.fio)}
              setFocus={setFocusPhone}
              isGreenApprove={isGreenApprove}
              onChange={handleChange}
              handleBlur={handleBlur}
              value={values.phone}
              error={errors.phone}
              touched={touched.phone}
              name="phone"
              id="phone"
            />

            {hasBirth && (
              <BirthField
                onChange={handleChange}
                handleBlur={handleBlur}
                value={values.birthDate}
                error={errors.birthDate}
                touched={touched.birthDate}
                name="birthDate"
                id="birthDate"
              />
            )}
            {hasCity && (
              <CityField
                cities={cities}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                error={errors.localityAddress}
                touched={touched.localityAddress}
                name="localityAddress"
                id="localityAddress"
              />
            )}
            <AcceptmentField
              value={values.agree}
              onChange={handleChange}
              error={errors.agree}
              name="agree"
              id="agree"
              touched={touched.agree}
            />
            <Button
              classes={{ root: styles.btn }}
              type="submit"
              disabled={isSubmitting}
              onClick={() => handleClick(errors)}
            >
              {formBtnText}
            </Button>
          </form>
        )
      }}
    </Formik>
  )
}
